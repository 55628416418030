import {
  DerivedDirectoryStatuses,
  DirectoryData,
  DirectoryProviders,
  DirectoryStats,
  DirectoryTypes
} from '~/api/types/directoryData.ts';
import { ProviderNames } from '~/utils/providers/idpLabelConfiguration.tsx';
import { externalLinks } from '~/consts.ts';
import {
  ApiErrorInstance,
  isApi5xxResponseStatus,
  withHandleError
} from '~/api';
import { HttpStatusCode } from 'axios';
import { getLocalizedString } from '~/i18n/utils.ts';
import { Params } from '@solidjs/router';
import { AppContexts, getAppContext } from '~/utils/commonUtils.ts';
import {
  CUSTOMER_PORTAL_BASE_PATHS,
  CustomerPortalPath,
  DirectoryDetailsTabTypes,
  EnvironmentPagePath,
  getSlug,
  replacePathParams
} from '~/components/AppRouter/utils.ts';
import { ORGANIZATION_TAB_TYPES } from '~/pages/Organization/utils.tsx';
import { createEffect, createSignal, untrack } from 'solid-js';
import { useOrganizationContext } from '~/components/AppData/OrganizationContext.tsx';
import {
  doesDirectoryHaveUpdatedData,
  doesDirectoryHaveUpdatedGroupData,
  doesDirectoryHaveUpdatedUserData
} from '~/pages/DirectorySync/DirectoryEvents/utils.tsx';

const getTranslationKey = (translationKey: string) =>
  `directory_sync.${translationKey}`;

type DirectoryProviderTemplate = {
  displayName: string;
  supportedConnectionTypes: DirectoryTypes[];
  provider: DirectoryProviders;
  integrationDocsUrl?: {
    [DirectoryTypes.SCIM]?: string;
  };
};

const getDirectoryProviderListTemplate = (): DirectoryProviderTemplate[] => [
  {
    displayName: ProviderNames[DirectoryProviders.OKTA].label,
    supportedConnectionTypes: [DirectoryTypes.SCIM],
    provider: DirectoryProviders.OKTA,
    integrationDocsUrl: {
      [DirectoryTypes.SCIM]: externalLinks.idpIntegrationDocLinks.scim.okta
    }
  },
  {
    displayName: ProviderNames[DirectoryProviders.ONELOGIN].label,
    supportedConnectionTypes: [DirectoryTypes.SCIM],
    provider: DirectoryProviders.ONELOGIN,
    integrationDocsUrl: {
      [DirectoryTypes.SCIM]: externalLinks.idpIntegrationDocLinks.scim.onelogin
    }
  },
  {
    displayName: ProviderNames[DirectoryProviders.MICROSOFT_AD].label,
    supportedConnectionTypes: [DirectoryTypes.SCIM],
    provider: DirectoryProviders.MICROSOFT_AD,
    integrationDocsUrl: {
      [DirectoryTypes.SCIM]: externalLinks.idpIntegrationDocLinks.scim.onelogin
    }
  },
  {
    displayName: ProviderNames[DirectoryProviders.CUSTOM].label,
    supportedConnectionTypes: [DirectoryTypes.SCIM],
    provider: DirectoryProviders.CUSTOM,
    integrationDocsUrl: {
      [DirectoryTypes.SCIM]: externalLinks.idpIntegrationDocLinks.scim.custom
    }
  }
];

const getActiveDirectoryFromList = (
  directories: DirectoryData[]
): DirectoryData | null =>
  directories.find((d) => d.id && d.directoryType === DirectoryTypes.SCIM) ||
  null;

function getDirectoryListUrl({ params }: { params: Params }): string {
  const appContext = getAppContext();
  if (appContext === AppContexts.SCALEKIT_DASHBOARD) {
    return getSlug.environmentById(
      params.environmentId,
      replacePathParams(
        EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_OVERVIEW,
        {
          organizationId: params.organizationId,
          tabType: ORGANIZATION_TAB_TYPES.DIRECTORY_SYNC
        }
      ) as EnvironmentPagePath,
      true
    );
  } else if (appContext === AppContexts.CUSTOMER_PORTAL) {
    return getSlug.portalLink(
      params.organizationId,
      CustomerPortalPath.LIST_DIRECTORIES,
      CUSTOMER_PORTAL_BASE_PATHS.DIRECTORY_SYNC,
      true
    );
  }
  throw new Error('Invalid app context');
}

const getDerivedDirectoryStatus = (
  directory: DirectoryData
): DerivedDirectoryStatuses => {
  if (!directory.lastSyncedAt) {
    return DerivedDirectoryStatuses.WAITING_TO_CONNECT;
  }
  return DerivedDirectoryStatuses.CONNECTED;
};

const getScrollableContainerId = (tabType: DirectoryDetailsTabTypes) =>
  `scrollable-container-${tabType}`;

const errorTranslationBasePath = 'server_errors.directory_sync';

const handleDirectoryCreateErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(`${errorTranslationBasePath}.create_failed`, {
      intl
    });
  }
});

const createDirectoryDataWatcher = (tabType: DirectoryDetailsTabTypes) => {
  const orgData = useOrganizationContext();

  const hasNewDataSignal = createSignal(false);
  const [directoryStats, setDirectoryStats] =
    createSignal<DirectoryStats | null>();

  let comparerFn: Function;

  switch (tabType) {
    case DirectoryDetailsTabTypes.EVENTS:
      comparerFn = doesDirectoryHaveUpdatedData;
      break;
    case DirectoryDetailsTabTypes.USERS:
      comparerFn = doesDirectoryHaveUpdatedUserData;
      break;
    case DirectoryDetailsTabTypes.GROUPS:
      comparerFn = doesDirectoryHaveUpdatedGroupData;
      break;
  }

  createEffect(() => {
    if (!comparerFn) {
      return;
    }
    if (
      directoryStats() &&
      comparerFn(directoryStats()!, orgData.selectedDirectory!.stats)
    ) {
      untrack(() => hasNewDataSignal[1](true));
    }
    untrack(() => setDirectoryStats(orgData.selectedDirectory!.stats));
  });

  return hasNewDataSignal;
};

export {
  getTranslationKey,
  getDirectoryProviderListTemplate,
  getActiveDirectoryFromList,
  getDirectoryListUrl,
  getDerivedDirectoryStatus,
  getScrollableContainerId,
  handleDirectoryCreateErrors,
  createDirectoryDataWatcher
};
