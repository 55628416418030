import { JSXElement, mergeProps, Show } from 'solid-js';
import styles from './named-icon.module.scss';
import { getNameInitials } from '~/utils/commonUtils.ts';
import { twMerge } from 'tailwind-merge';
import { UserRoundIcon } from 'lucide-solid';

type NamedIconProps = {
  src?: string;
  altText?: string;
  w?: number | string;
  name?: string;
  rounded?: boolean;
  class?: string;
  includeLastName?: boolean;
  showInitials?: boolean;
};

const DEFAULT_COLORS = 'bg-fg-subtle text-accent-fg';
export default function NamedIcon(props: NamedIconProps): JSXElement {
  const merged = mergeProps(
    {
      w: 'auto',
      name: '',
      rounded: false,
      class: '',
      includeLastName: false,
      showInitials: true
    },
    props
  );

  const sideDimension = merged.w !== 'auto' ? `${merged.w}px` : merged.w;
  return (
    <div
      class={`${styles.iconContainer} ${twMerge(DEFAULT_COLORS, merged.class)}`}
      classList={{
        'rounded-md': !merged.rounded,
        'rounded-full': merged.rounded,
        [styles.textOnly]: !merged.src,
        [styles.anonymousBg]: !merged.name
      }}
      style={{ width: sideDimension, height: sideDimension }}
    >
      <Show
        when={merged.name}
        fallback={
          <UserRoundIcon
            width={18}
            strokeWidth={1}
          />
        }
      >
        <Show
          when={!!merged.src}
          fallback={
            merged.showInitials
              ? getNameInitials(merged.name, merged.includeLastName)
              : merged.name
          }
        >
          <img
            src={merged.src}
            alt={merged.name}
          />
        </Show>
      </Show>
    </div>
  );
}
