import { getRequestConfig, withMakeRequest } from '~/api';
import Request from '~/api/Request.ts';
import {
  FeatureFlagKeys,
  FeatureSettings,
  ListEnvironmentFeaturesRequest,
  ListEnvironmentFeaturesResponse,
  ToggleEnvironmentFeatureRequest
} from '~/api/types/featureFlagData.ts';

const getEnvironmentFeatures: (
  request: ListEnvironmentFeaturesRequest
) => Promise<FeatureSettings[]> = withMakeRequest(
  async ({ environmentId }: ListEnvironmentFeaturesRequest) =>
    await Request.getInstance().get(
      `/environments/${environmentId}/features`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: ListEnvironmentFeaturesResponse) => data.features
);

const toggleEnvironmentFeature: (
  request: ToggleEnvironmentFeatureRequest
) => Promise<Partial<Record<FeatureFlagKeys, boolean>>> = withMakeRequest(
  async ({ environmentId, feature, status }: ToggleEnvironmentFeatureRequest) =>
    await Request.getInstance().post(
      `/environments/${environmentId}/features/${feature}:${status}`,
      {},
      getRequestConfig({ addEnvDomain: true })
    )
);

export { getEnvironmentFeatures, toggleEnvironmentFeature };
