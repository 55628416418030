import {
  ConnectionData,
  ConnectionTypes,
  Providers
} from '~/api/types/connectionData.ts';
import { Params } from '@solidjs/router';
import {
  CUSTOMER_PORTAL_BASE_PATHS,
  CustomerPortalPath,
  DirectoryDetailsTabTypes,
  EnvironmentPagePath,
  getSlug,
  replacePathParams
} from '~/components/AppRouter/utils.ts';
import { ORGANIZATION_TAB_TYPES } from '~/pages/Organization/utils.tsx';
import {
  AppContexts,
  getAppContext,
  getCamelCase
} from '~/utils/commonUtils.ts';
import { ProviderNames } from '~/utils/providers/idpLabelConfiguration.tsx';
import {
  ApiErrorInstance,
  isApi5xxResponseStatus,
  withHandleError
} from '~/api';
import { HttpStatusCode } from 'axios';
import { getLocalizedString } from '~/i18n/utils.ts';
import { externalLinks } from '~/consts.ts';
import { UserProfileAttribute } from '~/api/types/userProfileAttributes.ts';

const getTranslationKey = (translationKey: string) =>
  `admin_portals.sso.${translationKey}`;

type IdpTemplate = {
  displayName: string;
  supportedConnectionTypes: ConnectionTypes[];
  provider: Providers;
  integrationDocsUrl?: {
    [ConnectionTypes.SAML]?: string;
    [ConnectionTypes.OIDC]?: string;
  };
};

const getIdpListTemplate = (): IdpTemplate[] => [
  {
    displayName: ProviderNames.OKTA.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.OKTA,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]: externalLinks.idpIntegrationDocLinks.saml.okta,
      [ConnectionTypes.OIDC]: externalLinks.idpIntegrationDocLinks.oidc.okta
    }
  },
  {
    displayName: ProviderNames.ONELOGIN.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.ONELOGIN,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]:
        externalLinks.idpIntegrationDocLinks.saml.onelogin,
      [ConnectionTypes.OIDC]: externalLinks.idpIntegrationDocLinks.oidc.onelogin
    }
  },
  {
    displayName: ProviderNames.GOOGLE.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.GOOGLE,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]: externalLinks.idpIntegrationDocLinks.saml.google,
      [ConnectionTypes.OIDC]: externalLinks.idpIntegrationDocLinks.oidc.google
    }
  },
  {
    displayName: ProviderNames.MICROSOFT_AD.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.MICROSOFT_AD,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]:
        externalLinks.idpIntegrationDocLinks.saml.microsoftAd,
      [ConnectionTypes.OIDC]:
        externalLinks.idpIntegrationDocLinks.oidc.microsoftAd
    }
  },
  {
    displayName: ProviderNames.PING_IDENTITY.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.PING_IDENTITY,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]:
        externalLinks.idpIntegrationDocLinks.saml.pingIdentity,
      [ConnectionTypes.OIDC]:
        externalLinks.idpIntegrationDocLinks.oidc.pingIdentity
    }
  },
  {
    displayName: ProviderNames.JUMPCLOUD.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.JUMPCLOUD,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]:
        externalLinks.idpIntegrationDocLinks.saml.jumpcloud,
      [ConnectionTypes.OIDC]:
        externalLinks.idpIntegrationDocLinks.oidc.jumpcloud
    }
  },
  {
    displayName: ProviderNames.CUSTOM.label,
    supportedConnectionTypes: [ConnectionTypes.SAML, ConnectionTypes.OIDC],
    provider: Providers.CUSTOM,
    integrationDocsUrl: {
      [ConnectionTypes.SAML]: externalLinks.idpIntegrationDocLinks.saml.custom,
      [ConnectionTypes.OIDC]: externalLinks.idpIntegrationDocLinks.oidc.custom
    }
  }
];

type GetOrgResourceDetailsUrlParamsType = {
  params: Params;
  resourceId: string;
  resourceIdType: 'connectionId' | 'directoryId';
  additionalParams?: Record<string, string>;
};

function getOrgResourceDetailsUrl({
  params,
  resourceId,
  resourceIdType,
  additionalParams = {}
}: GetOrgResourceDetailsUrlParamsType): string {
  const appContext = getAppContext();
  if (resourceIdType === 'directoryId' && !additionalParams.directoryTabType) {
    additionalParams.directoryTabType = DirectoryDetailsTabTypes.CONFIGURATION;
  }
  if (appContext === AppContexts.SCALEKIT_DASHBOARD) {
    return getSlug.environmentById(
      params.environmentId,
      replacePathParams(
        resourceIdType === 'connectionId'
          ? EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_CONNECTION_DETAILS
          : EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_DIRECTORY_DETAILS,
        {
          organizationId: params.organizationId,
          [resourceIdType]: resourceId,
          tabType:
            resourceIdType === 'connectionId'
              ? ORGANIZATION_TAB_TYPES.SSO_CONNECTIONS
              : ORGANIZATION_TAB_TYPES.DIRECTORY_SYNC,
          ...additionalParams
        }
      ) as EnvironmentPagePath,
      true
    );
  } else if (appContext === AppContexts.CUSTOMER_PORTAL) {
    let resourcePath = CustomerPortalPath.CONNECTION_DETAILS;
    let basePath = CUSTOMER_PORTAL_BASE_PATHS.SSO;
    if (resourceIdType === 'directoryId') {
      resourcePath = CustomerPortalPath.DIRECTORY_DETAILS;
      basePath = CUSTOMER_PORTAL_BASE_PATHS.DIRECTORY_SYNC;
    }
    return getSlug.portalLink(
      params.organizationId,
      resourcePath,
      basePath,
      true,
      {
        [resourceIdType]: resourceId,
        ...additionalParams
      }
    );
  }
  throw new Error('Invalid app context');
}

type GetConnectionListUrlParamsType = {
  params: Params;
};

function getConnectionListUrl({
  params
}: GetConnectionListUrlParamsType): string {
  const appContext = getAppContext();
  if (appContext === AppContexts.SCALEKIT_DASHBOARD) {
    return getSlug.environmentById(
      params.environmentId,
      replacePathParams(
        EnvironmentPagePath.ENVIRONMENT_ORGANIZATION_DETAILS_OVERVIEW,
        {
          organizationId: params.organizationId,
          tabType: ORGANIZATION_TAB_TYPES.SSO_CONNECTIONS
        }
      ) as EnvironmentPagePath,
      true
    );
  } else if (appContext === AppContexts.CUSTOMER_PORTAL) {
    return getSlug.portalLink(
      params.organizationId,
      CustomerPortalPath.LIST_CONNECTIONS,
      CUSTOMER_PORTAL_BASE_PATHS.SSO,
      true
    );
  }
  throw new Error('Invalid app context');
}

function getDefaultAttributeMapping(connection: ConnectionData) {
  if (connection?.type === ConnectionTypes.SAML) {
    return {
      sub: 'nameid',
      givenName: 'given_name',
      familyName: 'family_name',
      email: 'email'
    };
  } else if (connection?.type === ConnectionTypes.OIDC) {
    return {
      sub: 'sub',
      givenName: 'given_name',
      familyName: 'family_name',
      email: 'email'
    };
  }
}

function getAttributeMapping(connection: ConnectionData) {
  return {
    ...getDefaultAttributeMapping(connection),
    ...(connection?.attributeMapping || {})
  };
}

function getIdpDocLink(connection: ConnectionData) {
  const idpList = getIdpListTemplate();
  const selectedIdp = idpList.find(
    ({ provider }) => provider === connection.provider
  );
  return selectedIdp
    ? (selectedIdp.integrationDocsUrl?.[connection.type] ?? '')
    : '';
}

type AttributesForConnectionTransformed = (UserProfileAttribute & {
  mappingKey: string;
})[];

const getAttributesForConnection: (params: {
  userProfileAttributes: UserProfileAttribute[];
  connection: ConnectionData;
}) => AttributesForConnectionTransformed = ({
  userProfileAttributes,
  connection
}) => {
  const connectionAttributeMapping = connection.attributeMapping || {};
  const mappingData: AttributesForConnectionTransformed = [];
  userProfileAttributes
    .filter(({ enabled }) => !!enabled)
    .forEach((attribute) => {
      const mappingKey =
        connectionAttributeMapping[getCamelCase(attribute.key)] ||
        (connection.type === ConnectionTypes.SAML
          ? attribute.ssoAdditionInfo?.defaultSamlMapping
          : attribute.ssoAdditionInfo?.defaultOidcMapping);
      mappingKey &&
        mappingData.push({
          ...attribute,
          mappingKey
        });
    });
  return mappingData;
};

const translationBasePath = 'server_errors.sso';

const handleChangeProviderErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;

  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(`${translationBasePath}.change_provider_failed`, {
      intl
    });
  }
});

const handleOidcConfigurationError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;

  if (serverError.httpStatus === HttpStatusCode.NotFound) {
    // Invalid Issuer Url Scenario
    // @todo update based on the updated error_code
    // once the BE response structure has changed
    return getLocalizedString(
      `${translationBasePath}.invalid_oidc_issuer_url`,
      {
        intl
      }
    );
  }

  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(
      `${translationBasePath}.update_connection_failed`,
      {
        intl
      }
    );
  }
});

const handleSamlConfigurationError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;

  if (serverError.httpStatus === HttpStatusCode.InternalServerError) {
    // Invalid Metadata Url Scenario
    // @todo update based on the updated error_code
    // once the BE response structure has changed
    return serverError.error?.code === 13
      ? getLocalizedString(`${translationBasePath}.invalid_saml_metadata_url`, {
          intl
        })
      : getLocalizedString(`${translationBasePath}.update_connection_failed`, {
          intl
        });
  }

  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(
      `${translationBasePath}.update_connection_failed`,
      {
        intl
      }
    );
  }
});

const handleSsoStatusToggleError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;

  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(
      `${translationBasePath}.toggle_connection_failed`,
      {
        intl
      }
    );
  }
});

const handleDeleteConnectionError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(
      `${translationBasePath}.delete_connection_failed`,
      {
        intl
      }
    );
  }
});

export {
  getTranslationKey,
  getIdpListTemplate,
  getOrgResourceDetailsUrl,
  getConnectionListUrl,
  getDefaultAttributeMapping,
  getAttributeMapping,
  getIdpDocLink,
  getAttributesForConnection,
  handleChangeProviderErrors,
  handleOidcConfigurationError,
  handleSamlConfigurationError,
  handleSsoStatusToggleError,
  handleDeleteConnectionError
};
