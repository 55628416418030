export enum DirectoryEventTypes {
  DIR_USER_CREATE = 'scalekit.dir.user.create',
  DIR_USER_UPDATE = 'scalekit.dir.user.update',
  DIR_USER_DELETE = 'scalekit.dir.user.delete',
  DIR_GROUP_CREATE = 'scalekit.dir.group.create',
  DIR_GROUP_UPDATE = 'scalekit.dir.group.update',
  DIR_GROUP_DELETE = 'scalekit.dir.group.delete',
  DIR_DIR_ENABLE = 'scalekit.dir.sync.enable',
  DIR_DIR_DISABLE = 'scalekit.dir.sync.disable'
}

export interface EventData {
  id: string;
  type: string;
  occurredAt: string;
  data: Record<any, any>;
  rawPayload?: string;
}

export interface EventsListResponse {
  prevPageToken: string;
  nextPageToken: string;
  totalSize: number;
  events: Array<EventData>;
}
