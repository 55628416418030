import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  Show,
  untrack
} from 'solid-js';
import { createQuery } from '@tanstack/solid-query';
import {
  getEnvironmentFeatures,
  getEnvironmentFeaturesQueryKey,
  getFeatureFlags,
  getFeatureFlagsQueryKey,
  retryAuthorizedRequests
} from '~/api';
import { useAppData } from '~/components/AppData';
import SkLoader from '~/components/ui/SkLoader';
import {
  overrideFeatureFlagWithEnvFeatures,
  transformFeatureFlagResponse
} from '~/utils/features/featureFlag.tsx';
import { setGlobalStore } from '~/stores';
import logger from '~/utils/logger';
import { FeatureFlagMap } from '~/api/types/featureFlagData.ts';

type FeatureFlagLoaderProps = {
  children: JSXElement;
};

export default function FeatureFlagLoader(
  props: FeatureFlagLoaderProps
): JSXElement {
  const merged = mergeProps({}, props);
  const appData = useAppData();

  const [isDataLoaded, setIsDataLoaded] = createSignal(false);

  const featureFlagQuery = createQuery(() => ({
    queryKey: getFeatureFlagsQueryKey(),
    queryFn: async () => {
      if (!appData.member?.workspaceId) {
        return null;
      }
      return await getFeatureFlags({
        workspaceId: appData.member.workspaceId!
      });
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  const environmentFeaturesQuery = createQuery(() => ({
    queryKey: getEnvironmentFeaturesQueryKey(),
    queryFn: async () => {
      return await getEnvironmentFeatures({
        environmentId: appData.selectedEnvironment!.id
      });
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(() => {
    if (appData.isLoggedIn && !!appData.selectedEnvironment?.id) {
      untrack(() => {
        featureFlagQuery.refetch();
        environmentFeaturesQuery.refetch();
      });
      return;
    }
    untrack(() => setIsDataLoaded(true));
  });

  createEffect(() => {
    if (featureFlagQuery.isSuccess && featureFlagQuery.data) {
      let featureFlagMap: FeatureFlagMap;

      if (environmentFeaturesQuery.isSuccess && environmentFeaturesQuery.data) {
        // Override feature flags with environment features if both are available
        featureFlagMap = overrideFeatureFlagWithEnvFeatures(
          transformFeatureFlagResponse(featureFlagQuery.data),
          environmentFeaturesQuery.data.reduce((acc, feature) => {
            return {
              ...acc,
              [feature.name]: feature.enabled
            };
          }, {})
        );
        logger.info(
          'Setting overridden feature flags to global store',
          featureFlagMap
        );
      } else if (environmentFeaturesQuery.error) {
        // Only use feature flags if environment feature query fails
        featureFlagMap = transformFeatureFlagResponse(featureFlagQuery.data);
        logger.info(
          'Override feature flag failed. Updating global store',
          featureFlagMap
        );
      }
      untrack(() => {
        setGlobalStore('featureFlags', featureFlagMap);
        setIsDataLoaded(true);
      });
    } else if (featureFlagQuery.error) {
      logger.info('Updating empty object to globaStore feature flag', {});
      // Fallback if feature flag query fails
      untrack(() => {
        setGlobalStore('featureFlags', {});
        setIsDataLoaded(true);
      });
    }
  });

  return (
    <Show
      when={isDataLoaded()}
      fallback={<SkLoader />}
    >
      {merged.children}
    </Show>
  );
}
