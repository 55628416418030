import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { ERROR_CODES } from '~/api/serverErrors.ts';
import { appConfig } from '~/consts.ts';
import { getRandomInteger } from '~/utils/commonUtils.ts';
import { createApiError, isApiError } from '~/api/ApiResponse.ts';
import { globalStore } from '~/stores';
import { ScalekitAxiosConfig } from '~/api/Request.ts';
import logger from '~/utils/logger';

export enum RESPONSE_STATUSES {
  SUCCESS = 200,
  UNAUTHORIED = 401
}

export const API_HOST = ``;

const retryAuthorizedRequests = (failureCount: any, error: any) => {
  if (
    isApiError(error) &&
    (error.httpStatus === HttpStatusCode.Unauthorized ||
      error.httpStatus === HttpStatusCode.NotFound)
  ) {
    return false;
  }
  return failureCount < appConfig.apiRetries;
};

// Specifically written for local environment to have a 200 - 500ms delay for all api calls
const apiSleep = async () => {
  if (import.meta.env.MODE !== 'development') {
    return;
  }
  return new Promise((resolve) => {
    setTimeout(resolve, getRandomInteger(1, 50));
  });
};

const withMakeRequest: WithApiCall<
  (...args: any[]) => Promise<AxiosResponse>,
  (data: any) => any
> = (fetcher, modifier) => {
  return async (
    ...args: Parameters<(...args: any[]) => Promise<AxiosResponse>>
  ) => {
    await apiSleep();
    try {
      const res = await fetcher(...args);

      if (String(res.status).startsWith('2')) {
        try {
          if (res.data) {
            const data = JSON.parse(res.data);
            return modifier ? modifier(data) : data;
          }
          return res.data;
        } catch (parseError) {
          // got success response but not JSON response
          // @todo need to handle better
          logger.error(
            `Failed to parse response data for api ${res.config.url}`,
            parseError
          );
          return {};
        }
      }
      throw createApiError({
        errorCode: ERROR_CODES.UNKNOWN,
        status: res.status
      });
    } catch (e: any) {
      let errorCode = ERROR_CODES.GENERIC;
      let apiError;

      if (e.isAxiosError) {
        if (e.response?.status === HttpStatusCode.Unauthorized) {
          errorCode = ERROR_CODES.UNAUTHORIZED;
        } else if (e.response?.status === HttpStatusCode.Forbidden) {
          errorCode = ERROR_CODES.FORBIDDEN;
        } else if (String(e.response?.status).startsWith('4')) {
          errorCode = ERROR_CODES.BAD_REQUEST;
        } else if (isApi5xxResponseStatus(e.response?.status)) {
          errorCode = ERROR_CODES.SERVER_RESPONSE_5XX;
        } else {
          errorCode = ERROR_CODES.UNKNOWN;
        }

        apiError = createApiError({
          errorCode,
          status: e.response?.status as HttpStatusCode,
          error: getDetailsFromError(e)
        });
      } else if (isApiError(e)) {
        apiError = e;
      }
      throw apiError;
    }
  };
};

const getRequestHeaders = () => {
  const token = globalStore.portalAuthToken;
  return {
    Authorization: `Bearer ${token}`
  };
};

const getDetailsFromError = (error: AxiosError) => {
  try {
    const data = error.response?.data;
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
  } catch (e) {}
  return {};
};

const isApi5xxResponseStatus = (httpStatus: number) => {
  return String(httpStatus).startsWith('5');
};

const getRequestConfig = (
  config: ScalekitAxiosConfig
): ScalekitAxiosConfig => ({
  addXRequestedWith: true,
  camelCaseResponse: true,
  ...config
});

export {
  apiSleep,
  retryAuthorizedRequests,
  withMakeRequest,
  getRequestHeaders,
  isApi5xxResponseStatus,
  getRequestConfig
};
