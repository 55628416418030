export enum FeatureFlagKeys {
  SOCIAL_CONNECTION = 'social_connection',
  MANAGE_ENVIRONMENT = 'manage_environment',
  PORTAL_CUSTOMIZATION = 'portal_customization',
  WEBHOOKS = 'webhooks',
  SINGLE_SIGNON = 'sso',
  DIRECTORY_SYNC = 'dir_sync',
  GBRA = 'gbra',
  BILLING = 'billing',
  SIGNUP = 'signup'
}

type FeatureFlagValue = string | boolean;

export interface FeatureSettings {
  name: FeatureFlagKeys;
  enabled: boolean;
}

export interface FeatureFlagData {
  value: FeatureFlagValue;
  key: FeatureFlagKeys;
  reason?: string;
  variant?: string;
  metadata?: Record<string, any>;
}

export interface OpenFeatureListResponse {
  flags: FeatureFlagData[];
}

export type FeatureFlagMap = Partial<
  Record<FeatureFlagKeys, FeatureFlagData>
> | null;

export interface ListEnvironmentFeaturesRequest {
  environmentId: string;
}

export interface ListEnvironmentFeaturesResponse {
  features: FeatureSettings[];
}

export interface ToggleEnvironmentFeatureRequest {
  environmentId: string;
  feature: FeatureFlagKeys;
  status: 'enable' | 'disable';
}
