import { JSXElement, mergeProps, Show } from 'solid-js';
import { AppDataProvider } from '~/components/AppData';
import { WorkspaceApiConnector } from '~/api';
import SkLoader from '~/components/ui/SkLoader';
import { ConfirmModalProvider } from '~/components/ui/Modal';
import FeatureFlagLoader from '~/components/FeatureFlags';

type WorkspaceContainerProps = {
  children: JSXElement;
};

export default function WorkspaceContainer(
  props: WorkspaceContainerProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <WorkspaceApiConnector>
      <ConfirmModalProvider>
        <AppDataProvider>
          {(isDataLoaded) => (
            <Show
              when={isDataLoaded}
              fallback={<SkLoader />}
            >
              <FeatureFlagLoader>{merged.children}</FeatureFlagLoader>
            </Show>
          )}
        </AppDataProvider>
      </ConfirmModalProvider>
    </WorkspaceApiConnector>
  );
}
