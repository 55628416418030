import { ORGANIZATION_TAB_TYPES } from '~/pages/Organization/utils.tsx';

export const PORTAL_BASE_SLUG = '/portals';

export enum OrgDetailsDataType {
  SSO_CONNECTIONS = 'sso',
  DIRECTORIES = 'dir-sync'
}

export enum DirectoryDetailsTabTypes {
  CONFIGURATION = 'configuration',
  EVENTS = 'events',
  USERS = 'users',
  GROUPS = 'groups'
}

export enum CUSTOMER_PORTAL_BASE_PATHS {
  UNKNOWN = '/:portalType',
  SSO = `/${OrgDetailsDataType.SSO_CONNECTIONS}`,
  DIRECTORY_SYNC = `/${OrgDetailsDataType.DIRECTORIES}`
}

enum AppBaseNamespaces {
  WORKSPACE = '/ws',
  CUSTOMER_PORTAL = PORTAL_BASE_SLUG,
  CUSTOMER_PORTAL_SSO = `${PORTAL_BASE_SLUG}/:portalType`,
  HANDLERS = '/ui-handlers'
}

enum PublicPagePath {
  LOGIN = '/login',
  SIGNUP = '/signup'
}
enum EnvironmentPagePath {
  ENVIROMNEMT_GETTING_STARTED = '/getting-started',
  ENVIROMNEMT_ORGANIZATIONS = '/organizations',
  ENVIRONMENT_ORGANIZATION_DETAILS_OVERVIEW = '/organizations/:organizationId/:tabType',
  ENVIRONMENT_ORGANIZATION_DETAILS_CONNECTION_DETAILS = `/organizations/:organizationId/${ORGANIZATION_TAB_TYPES.SSO_CONNECTIONS}/:connectionId`,
  ENVIRONMENT_ORGANIZATION_DETAILS_DIRECTORY_DETAILS = `/organizations/:organizationId/${ORGANIZATION_TAB_TYPES.DIRECTORY_SYNC}/:directoryId/:directoryTabType`,
  ENVIROMNEMT_AUTHENTICATION = '/authentication',
  ENVIROMNEMT_AUDIT_LOGS = '/audit-logs',
  ENVIROMNEMT_SETTINGS = '/settings',
  ENVIRONMENT_API_CREDENTIALS = '/api-credentials',
  ENVIRONMENT_SINGLE_SIGN_ON = '/sso/:tabType',
  ENVIRONMENT_DIRECTORY_SYNC = '/scim/:tabType',
  ENVIRONMENT_SOCIAL_CONNECTIONS = '/connections/social',
  ENVIRONMENT_SOCIAL_CONNECTION_DETAILS = '/connections/social/:connectionId',
  ENVIRONMENT_CUSTOMIZATIONS = '/customizations',
  ENVIRONMENT_WEBHOOKS = '/webhooks',
  ENVIRONMENT_ROLES = '/roles'
}
enum WorkspacePagePath {
  WORKSPACE_GENERAL = '/settings/general',
  WORKSPACE_MEMBERS = '/members',
  WORKSPACE_MANAGE_ENVIRONMENTS = '/manage/environments',
  WORKSPACE_BILLING = '/billing'
}

enum MyAccountPagePath {
  MYACCOUNT_PROFILE = '/profile',
  MYACCOUNT_PREFERENCES = '/preferences',
  MYACCOUNT_NOTIFICATIONS = '/notifications'
}

enum CustomerPortalPath {
  LIST_CONNECTIONS = '/organizations/:organizationId/connections',
  CONNECTION_DETAILS = '/organizations/:organizationId/connections/:connectionId',
  CONNECTION_DOC = '/organizations/:organizationId/connections/:connectionId/docs',
  LIST_DIRECTORIES = '/organizations/:organizationId/directories',
  DIRECTORY_DETAILS = '/organizations/:organizationId/directories/:directoryId/:directoryTabType'
}

enum HandlersPath {
  TEST_SSO = '/sso/:connectionId/test',
  LOGIN_CALLBACK = '/auth/callback',
  LOGIN_BOX = '/hosted/login'
}

export type AnyAppPath =
  | AppBaseNamespaces
  | PublicPagePath
  | EnvironmentPagePath
  | WorkspacePagePath
  | MyAccountPagePath
  | CustomerPortalPath
  | HandlersPath;

function shouldIgnoreUnauthorizedLoginRedirection(): boolean {
  // Add all public page routes here to keep the unprotected
  // Else non-logged in users will be redirected to /login for those routes
  const publicPaths: Set<string> = new Set([
    getSlug.publicPage(PublicPagePath.LOGIN, true),
    getSlug.publicPage(PublicPagePath.SIGNUP, true)
  ]);
  return publicPaths.has(window.location.pathname);
}

const replacePathParams = (
  path: AnyAppPath,
  pathParams: Record<string, any> = {}
): string => {
  return Object.keys(pathParams).reduce(
    (currentPath: string, currentParam: string) => {
      return pathParams[currentParam]
        ? currentPath.replace(`:${currentParam}`, pathParams[currentParam])
        : currentPath;
    },
    path
  );
};

const getFullPath = (
  path: string,
  includeNamespace: boolean = false,
  nameSpace: AppBaseNamespaces | string
) => (includeNamespace ? `${nameSpace}${path}` : path);

const getSlug = {
  workspaceBaseNamespace: () => AppBaseNamespaces.WORKSPACE,
  customerPortalBaseNamespace: () => AppBaseNamespaces.CUSTOMER_PORTAL,
  customerPortalSSOBaseNamespace: () =>
    `${AppBaseNamespaces.CUSTOMER_PORTAL}${CUSTOMER_PORTAL_BASE_PATHS.SSO}`,
  customerPortalDirSyncBaseNamespace: () =>
    `${AppBaseNamespaces.CUSTOMER_PORTAL}${CUSTOMER_PORTAL_BASE_PATHS.DIRECTORY_SYNC}`,
  getHandlersBaseNamespace: () => AppBaseNamespaces.HANDLERS,
  publicPage: (path: PublicPagePath, includeNamespace?: boolean) =>
    getFullPath(`${path}`, includeNamespace, AppBaseNamespaces.WORKSPACE),
  environmentById: (
    envId?: string,
    path?: EnvironmentPagePath,
    includeNamespace?: boolean
  ) => {
    const environmentId: string = envId || ':environmentId';
    return getFullPath(
      `/environments/${environmentId}${path ? path : ''}`,
      includeNamespace,
      AppBaseNamespaces.WORKSPACE
    );
  },
  workspace: (path: WorkspacePagePath, includeNamespace?: boolean) =>
    getFullPath(
      `/workspace${path}`,
      includeNamespace,
      AppBaseNamespaces.WORKSPACE
    ),
  myAccount: (path: MyAccountPagePath, includeNamespace?: boolean) =>
    getFullPath(`/my${path}`, includeNamespace, AppBaseNamespaces.WORKSPACE),
  portalLink: (
    organizationId?: string,
    path: string = CustomerPortalPath.LIST_CONNECTIONS,
    portalTypeBase: CUSTOMER_PORTAL_BASE_PATHS = CUSTOMER_PORTAL_BASE_PATHS.UNKNOWN,
    includeNamespace?: boolean,
    pathParams: Record<string, any> = {}
  ): string => {
    const namespacePrefix = includeNamespace ? '' : portalTypeBase;
    let resourceTypeBase = '';

    if (portalTypeBase === CUSTOMER_PORTAL_BASE_PATHS.SSO) {
      resourceTypeBase = getSlug.customerPortalSSOBaseNamespace();
    } else if (portalTypeBase === CUSTOMER_PORTAL_BASE_PATHS.DIRECTORY_SYNC) {
      resourceTypeBase = getSlug.customerPortalDirSyncBaseNamespace();
    }

    const pathFragment = getFullPath(
      replacePathParams(path as AnyAppPath, {
        organizationId,
        ...pathParams
      }),
      includeNamespace,
      resourceTypeBase
    );

    return `${namespacePrefix}${pathFragment}`;
  },
  handlers: (
    path: string,
    includeNamespace?: boolean,
    pathParams: Record<string, any> = {}
  ): string => {
    return getFullPath(
      replacePathParams(path as AnyAppPath, pathParams),
      includeNamespace,
      AppBaseNamespaces.HANDLERS
    );
  }
};

export {
  AppBaseNamespaces,
  PublicPagePath,
  EnvironmentPagePath,
  WorkspacePagePath,
  MyAccountPagePath,
  CustomerPortalPath,
  HandlersPath,
  shouldIgnoreUnauthorizedLoginRedirection,
  replacePathParams,
  getSlug
};
