import { Component, For, JSXElement, mergeProps, splitProps } from 'solid-js';
import * as Tabs from './Component.tsx';
import './tab.utils.scss';

export type TabListType = {
  id: string;
  label: JSXElement;
  disabled?: boolean;
  content: Component;
};

type TabWrapperProps = {
  list: TabListType[];
};

type TabProps = Tabs.RootProps & TabWrapperProps;

export const TabWrapper = (props: TabProps) => {
  const merged = mergeProps({}, props);
  const [wrapperProps, arkProps] = splitProps(merged, ['list']);

  return (
    <Tabs.Root {...arkProps}>
      <Tabs.List>
        <For each={wrapperProps.list}>
          {(item) => (
            <Tabs.Trigger
              value={item.id}
              disabled={!!item.disabled}
              class={`px-3`}
            >
              {item.label}
            </Tabs.Trigger>
          )}
        </For>
        <Tabs.Indicator />
      </Tabs.List>
      <For each={wrapperProps.list}>
        {(item) => {
          // Consumed as a component instead of JSXElement
          // to initialise lazily
          // otherwise the component would be called once
          // during the initialization of the list prop of this component
          // This also allows lazy bundling and mounting of the content component
          const ContentComponent = item.content;
          return (
            <Tabs.Content value={item.id}>
              <ContentComponent />
            </Tabs.Content>
          );
        }}
      </For>
    </Tabs.Root>
  );
};
