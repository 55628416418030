import { Navigate, Route, Router } from '@solidjs/router';
import { getSlug } from '~/components/AppRouter/utils.ts';
import WorkspaceAppRoutes from '~/components/AppRouter/WorkspaceAppRoutes.tsx';
import AdminPortalAppRoutes from '~/components/AppRouter/AdminPortalAppRoutes.tsx';
import AppContainer from '~/components/AppContainer';
import { Component, lazy } from 'solid-js';
import UiHandlerRoutes from '~/components/AppRouter/UiHandlerRoutes.tsx';

const NotFound404 = lazy(() => import('~/pages/NotFound404'));

export default function AppRouter() {
  return (
    <Router root={AppContainer as Component}>
      <WorkspaceAppRoutes />
      <AdminPortalAppRoutes />
      <UiHandlerRoutes />
      {/*
       * Fallback route redirects to dashboard
       */}
      <Route
        path="/"
        component={() => <Navigate href={getSlug.workspaceBaseNamespace()} />}
      />
      {/*
       * 404 handling
       */}
      <Route
        path="*"
        component={NotFound404}
      />
    </Router>
  );
}
