import {
  ApiConfigData,
  ApiConfigUpdateRequest,
  ApiConfigUpdateResponse,
  CreateClientSecretResponse,
  CreateEnvironmentRequest,
  EnvironmentData,
  EnvironmentUpdateRequest,
  getRequestConfig,
  MemberData,
  MemberDataUpdateRequest,
  MemberListResponse,
  OrganizationData,
  OrganizationSettingsUpdateRequest,
  OrganizationsListResponse,
  withMakeRequest,
  WorkspaceData
} from './';
import Request from './Request.ts';
import {
  ConnectionData,
  ConnectionUpdateResponse,
  CreateConnectionRequest,
  TestConnectionResult,
  ToggleConnectionResponse,
  UpdateConnectionDataRequest
} from '~/api/types/connectionData.ts';
import { AppBaseNamespaces } from '~/components/AppRouter/utils.ts';
import { DomainData, DomainListResponse } from '~/api/types/domainData.ts';
import {
  GeneratePortalLinkResponse,
  PortalLink,
  PortalLinkListResponse
} from '~/api/types/portalLinks.ts';
import { PortalSessionResponse } from '~/api/types/commons.ts';
import {
  PortalCustomizationRequest,
  PortalCustomizationSettings
} from '~/api/types/customisationData.ts';
import { PortalCustomizationResponse } from './types/customisationData.ts';

import {
  UserProfileAttribute,
  UserProfileAttributeCreateRequest,
  UserProfileAttributeCreateResponse,
  UserProfileAttributesListResponse,
  UserProfileAttributeUpdateRequest,
  UserProfileAttributeUpdateResponse
} from '~/api/types/userProfileAttributes.ts';
import {
  FeatureFlagData,
  OpenFeatureListResponse
} from '~/api/types/featureFlagData.ts';
import {
  CreateEnvironmentAssetUploadLinkResponse,
  EnvironmentAssetTypes
} from '~/api/types/environmentAssetData.ts';
import { GenerateWebhookPortalLinkResponse } from '~/api/types/webhookData.ts';
import {
  CreateDirectoryRequest,
  CreateDirectoryResponse,
  DirectoryData,
  DirectoryUser,
  GenerateDirectorySecretRequest,
  GenerateDirectorySecretResponse,
  GetDirectoryByIdResponse,
  ListDirectoryGroupRequest,
  ListDirectoryGroupResponse,
  ListDirectoryResponse,
  ListDirectoryUserRequest,
  ListDirectoryUserResponse
} from '~/api/types/directoryData.ts';
import {
  DirectoryEventTypes,
  EventData,
  EventsListResponse
} from '~/api/types/eventData.ts';
import camelcaseKeys from 'camelcase-keys';
import {
  SignupWithEmailRequest,
  SignupWithEmailResponse
} from '~/api/types/signupData.ts';

const getFeatureFlags: (context: {
  workspaceId: string;
}) => Promise<FeatureFlagData[]> = withMakeRequest(
  async (context: { workspaceId: string }) =>
    await Request.getInstance().post(
      `${window.location.origin}/ofrep/v1/evaluate/flags`,
      { context }
    ),
  (data: OpenFeatureListResponse) => data.flags
);

const signup: (
  request: SignupWithEmailRequest
) => Promise<SignupWithEmailResponse> = withMakeRequest(
  async (request: SignupWithEmailRequest) =>
    await Request.getInstance().post(`${window.location.origin}/app/signup`, {
      request
    })
);

const login: (email: string) => Promise<WorkspaceData> = withMakeRequest(
  async (email) =>
    await Request.getInstance().post(`${window.location.origin}/app/login`, {
      email,
      redirectUri: `${window.location.origin}${AppBaseNamespaces.WORKSPACE}/`
    })
);

const logoutMember: () => Promise<boolean> = withMakeRequest(
  async () =>
    await Request.getInstance().post(`${window.location.origin}/app/logout`),
  () => true
);

const getOrganizations: (
  pageToken: string,
  pageSize: number
) => Promise<OrganizationsListResponse> = withMakeRequest(
  async (pageToken: string, pageSize: number) => {
    return await Request.getInstance().get(
      `/organizations?page_token=${pageToken}&page_size=${pageSize}`,
      getRequestConfig({ addEnvDomain: true })
    );
  }
);

const searchOrganizations: (
  query: string,
  pageToken: string,
  pageSize: number
) => Promise<OrganizationsListResponse> = withMakeRequest(
  async (query: string, pageToken: number, pageSize: number) =>
    await Request.getInstance().get(
      `/organizations:search?query=${encodeURIComponent(query)}&page_token=${pageToken}&page_size=${pageSize}`,
      getRequestConfig({ addEnvDomain: true })
    )
);

const getOrganizationById: (orgId: string) => Promise<OrganizationData> =
  withMakeRequest(
    async (orgId) =>
      await Request.getInstance().get(
        `/organizations/${orgId}`,
        getRequestConfig({ addEnvDomain: true })
      ),
    (data) => data.organization
  );

const addOrganization: (
  orgData: OrganizationData
) => Promise<OrganizationData> = withMakeRequest(
  async (orgData: OrganizationData) =>
    await Request.getInstance().post(
      `/organizations`,
      orgData,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.organization as OrganizationData
);

const updateOrganization: (
  orgId: string,
  orgData: OrganizationData
) => Promise<OrganizationData> = withMakeRequest(
  async (orgId, orgData) =>
    await Request.getInstance().patch(
      `/organizations/${orgId}`,
      orgData,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.organization as OrganizationData
);

const updateOrganizationSettings: (
  request: OrganizationSettingsUpdateRequest
) => Promise<OrganizationData> = withMakeRequest(
  async (request: OrganizationSettingsUpdateRequest) =>
    await Request.getInstance().patch(
      `/organizations/${request.id}/settings`,
      request.settings,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.organization as OrganizationData
);

const deleteOrganizationById: (
  organizationId: string
) => Promise<OrganizationsListResponse> = withMakeRequest(
  async (organizationId) =>
    await Request.getInstance().delete(
      `/organizations/${organizationId}`,
      getRequestConfig({ addEnvDomain: true })
    )
);

const getCurrentUserDetails: () => Promise<MemberData> = withMakeRequest(
  async () => await Request.getInstance().get('/members:this'),
  (data) => {
    return data.member as MemberData;
  }
);

const getCurrentWorkspaceDetails: () => Promise<WorkspaceData> =
  withMakeRequest(
    async () => await Request.getInstance().get('/workspaces:this'),
    (data) => data.workspace as WorkspaceData
  );

const getCurrentEnvironmentList: () => Promise<EnvironmentData[]> =
  withMakeRequest(
    async () => await Request.getInstance().get('/environments?page_size=100'),
    (data) => data.environments as Array<EnvironmentData> // Pagination ignored for now
  );

const createEnvironment: (
  data: CreateEnvironmentRequest
) => Promise<EnvironmentData> = withMakeRequest(
  async (data: EnvironmentData) =>
    await Request.getInstance().post('/environments', data),
  (data) => data.environment as EnvironmentData
);

const updateEnvironmentById: (
  environmentId: string,
  data: EnvironmentUpdateRequest
) => Promise<EnvironmentData> = withMakeRequest(
  async (envId: string, data: EnvironmentUpdateRequest) =>
    await Request.getInstance().patch(`/environments/${envId}`, data)
);

const createEnvironmentAssetUploadLink: (
  environmentId: string,
  category: EnvironmentAssetTypes,
  extension: string
) => Promise<CreateEnvironmentAssetUploadLinkResponse> = withMakeRequest(
  async (
    environmentId: string,
    category: EnvironmentAssetTypes,
    extension: string
  ) =>
    await Request.getInstance().post(
      `/environments/${environmentId}/asset`,
      { category, extension },
      getRequestConfig({ addEnvDomain: true })
    )
);

const uploadEnvironmentAsset: (url: string, file: File) => Promise<boolean> =
  withMakeRequest(
    async (url: string, file: File) =>
      await Request.getInstance().put(url, file, {
        headers: {
          'Content-Type': file.type
        }
      })
  );

const updateLoggedInMember: (
  data: MemberDataUpdateRequest | MemberData
) => Promise<MemberData> = withMakeRequest(
  async (data) => await Request.getInstance().patch('/members:this', data)
);

const updateWorkspace: (displayName: string) => Promise<WorkspaceData> =
  withMakeRequest(
    async (displayName) =>
      await Request.getInstance().patch('/workspaces:this', { displayName }),
    (data) => data.workspace as WorkspaceData
  );

const getWorkspaceMembers: () => Promise<MemberListResponse> = withMakeRequest(
  async () => await Request.getInstance().get('/members?pageSize=500')
);

const addMember: (m: MemberData) => Promise<MemberData> = withMakeRequest(
  async (memberData) =>
    await Request.getInstance().post('/members', memberData),
  (data) => data.member as MemberData
);

const deleteMember: (id: string) => Promise<boolean> = withMakeRequest(
  async (memberId) =>
    await Request.getInstance().delete(`/members/${memberId}`),
  () => true
);

const getClientCredentials: () => Promise<ApiConfigData[]> = withMakeRequest(
  async () =>
    await Request.getInstance().get(
      `/clients`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.clients
);

const updateClientCredentials: (
  clientId: string,
  data: ApiConfigUpdateRequest
) => Promise<ApiConfigUpdateResponse> = withMakeRequest(
  async (clientId, data) =>
    await Request.getInstance().patch(
      `/clients/${clientId}`,
      data,
      getRequestConfig({ addEnvDomain: true })
    )
);

const generateNewClientSecret: (
  clientId: string
) => Promise<CreateClientSecretResponse> = withMakeRequest(
  async (clientId) =>
    await Request.getInstance().post(
      `/clients/${clientId}/secrets`,
      {},
      getRequestConfig({ addEnvDomain: true })
    )
);

const deleteClientSecret: (
  clientId: string,
  secretId: string
) => Promise<CreateClientSecretResponse> = withMakeRequest(
  async (clientId, secretId) =>
    await Request.getInstance().delete(
      `/clients/${clientId}/secrets/${secretId}`,
      getRequestConfig({ addEnvDomain: true })
    )
);

const getConnectionList: (
  organizationId?: string
) => Promise<ConnectionData[]> = withMakeRequest(
  async (organizationId) => {
    const params = new URLSearchParams();
    params.set('include', 'all');
    if (organizationId) {
      params.set('organization_id', organizationId);
    }
    return await Request.getInstance().get(
      `/connections?${params.toString()}`,
      getRequestConfig({ addEnvDomain: true })
    );
  },
  (data) => data.connections
);

const addSSOConnectionForOrganization: (
  organizationId: string,
  connection: CreateConnectionRequest
) => Promise<ConnectionData> = withMakeRequest(
  async (organizationId, connection) =>
    Request.getInstance().post(
      `/organizations/${organizationId}/connections`,
      connection,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.connection
);

const addConnectionForEnvironment: (
  connection: CreateConnectionRequest
) => Promise<ConnectionData> = withMakeRequest(
  async (connection) =>
    await Request.getInstance().post(
      `/connections`,
      connection,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.connection
);

const getSSOConnectionDetails: (
  organizationId: string,
  connectionId: string
) => Promise<ConnectionData> = withMakeRequest(
  async (organizationId, connectionId) =>
    await Request.getInstance().get(
      `/organizations/${organizationId}/connections/${connectionId}`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.connection
);

const getEnvironmentConnectionDetails: (
  connectionId: string
) => Promise<ConnectionData> = withMakeRequest(
  async (connectionId) =>
    await Request.getInstance().get(
      `/connections/${connectionId}`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.connection
);

const updateConnection: (
  organizationId: string,
  connectionId: string,
  connection: UpdateConnectionDataRequest | ConnectionData
) => Promise<ConnectionData> = withMakeRequest(
  async (organizationId, connectionId, connection) =>
    await Request.getInstance().patch(
      `/organizations/${organizationId}/connections/${connectionId}`,
      connection,
      getRequestConfig({ addEnvDomain: true })
    ),
  ({ connection }: ConnectionUpdateResponse) => connection
);

const updateConnectionForEnvironment: (
  connectionId: string,
  connection: UpdateConnectionDataRequest | ConnectionData
) => Promise<ConnectionData> = withMakeRequest(
  async (connectionId, connection) =>
    await Request.getInstance().patch(
      `/connections/${connectionId}`,
      { ...connection, keyId: connection.provider },
      getRequestConfig({ addEnvDomain: true })
    ),
  ({ connection }: ConnectionUpdateResponse) => connection
);

const toggleConnection: (
  organizationId: string,
  connectionId: string,
  shouldEnable: boolean
) => Promise<boolean> = withMakeRequest(
  async (organizationId, connectionId, shouldEnable) =>
    await Request.getInstance().patch(
      `/organizations/${organizationId}/connections/${connectionId}:${shouldEnable ? 'enable' : 'disable'}`,
      {},
      getRequestConfig({ addEnvDomain: true })
    ),
  ({ enabled }: ToggleConnectionResponse) => enabled
);

const toggleEnvironmentConnection: (
  connectionId: string,
  shouldEnable: boolean
) => Promise<boolean> = withMakeRequest(
  async (connectionId, shouldEnable) =>
    await Request.getInstance().patch(
      `/connections/${connectionId}:${shouldEnable ? 'enable' : 'disable'}`,
      {},
      getRequestConfig({ addEnvDomain: true })
    ),
  ({ enabled }: ToggleConnectionResponse) => enabled
);

const deleteConnection: (
  organizationId: string,
  connectionId: string
) => Promise<boolean> = withMakeRequest(
  async (organizationId, connectionId) =>
    await Request.getInstance().delete(
      `/organizations/${organizationId}/connections/${connectionId}`,
      getRequestConfig({ addEnvDomain: true })
    ),
  () => true
);

const deleteEnvironmentConnection: (connectionId: string) => Promise<boolean> =
  withMakeRequest(
    async (connectionId) =>
      await Request.getInstance().delete(
        `/connections/${connectionId}`,
        getRequestConfig({ addEnvDomain: true })
      ),
    () => true
  );

const getTestConnectionResult: (
  connectionId: string,
  testRequestId: string
) => Promise<TestConnectionResult> = withMakeRequest(
  async (connectionId: string, testRequestId: string) =>
    await Request.getInstance().get(
      `/connections/${connectionId}/test-requests/${testRequestId}`
    )
);

const getOrganizationDomains: (
  organizationId: string
) => Promise<DomainListResponse> = withMakeRequest(
  async (organizationId) =>
    await Request.getInstance().get(
      `/organizations/${organizationId}/domains?page_size=100`,
      getRequestConfig({ addEnvDomain: true })
    )
);

const addOrganizationDomain: (
  organizationId: string,
  data: DomainData
) => Promise<DomainData> = withMakeRequest(
  async (organizationId, data) =>
    await Request.getInstance().post(
      `/organizations/${organizationId}/domains`,
      data,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data) => data.domain
);

const deleteOrganizationDomain: (
  organizationId: string,
  domainId: string
) => Promise<boolean> = withMakeRequest(
  async (organizationId, domainId) =>
    await Request.getInstance().delete(
      `/organizations/${organizationId}/domains/${domainId}`,
      getRequestConfig({ addEnvDomain: true })
    ),
  () => true
);

const getPortalLinksForOrg: (organizationId: string) => Promise<PortalLink[]> =
  withMakeRequest(
    async (organizationId) =>
      await Request.getInstance().get(
        `/organizations/${organizationId}/portal_links`,
        getRequestConfig({ addEnvDomain: true })
      ),
    (data: PortalLinkListResponse) => data.links
  );
const generatePortalLink: (organizationId: string) => Promise<PortalLink> =
  withMakeRequest(
    async (organizationId) =>
      await Request.getInstance().put(
        `/organizations/${organizationId}/portal_links`,
        {},
        getRequestConfig({ addEnvDomain: true })
      ),
    (data: GeneratePortalLinkResponse) => data.link || {}
  );

const deletePortalLink: (organizationId: string) => Promise<boolean> =
  withMakeRequest(
    async (organizationId) =>
      await Request.getInstance().delete(
        `/organizations/${organizationId}/portal_links`,
        getRequestConfig({ addEnvDomain: true })
      ),
    () => true
  );

const createPortalSession: (
  portalLinkId: string
) => Promise<PortalSessionResponse> = withMakeRequest(
  async (portalLinkId) =>
    await Request.getInstance().post(
      `${window.location.origin}/sessions:portal`,
      {
        token: portalLinkId
      }
    )
);

const getCurrentPortalCustomizationConfig: () => Promise<PortalCustomizationSettings | null> =
  withMakeRequest(
    async () =>
      await Request.getInstance().get(
        `/environments/-/portal_customizations`,
        getRequestConfig({ addEnvDomain: true })
      ),
    (data: PortalCustomizationResponse) =>
      data?.customizationSettings?.portalCustomization || null
  );

const getPortalCustomizationSettingsForEnvironment: (
  envId: string
) => Promise<PortalCustomizationSettings> = withMakeRequest(
  async (envId: string) =>
    await Request.getInstance().get(
      `/environments/${envId}/portal_customizations`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: PortalCustomizationResponse) =>
    data.customizationSettings?.portalCustomization || {}
);

const updatePortalCustomizationSettingsForEnvironment: (
  envId: string,
  data: PortalCustomizationRequest
) => Promise<PortalCustomizationSettings> = withMakeRequest(
  async (envId: string, data: PortalCustomizationRequest) =>
    await Request.getInstance().put(
      `/environments/${envId}/portal_customizations`,
      data,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: PortalCustomizationResponse) =>
    data.customizationSettings?.portalCustomization || {}
);

const getUserProfileAttributes: () => Promise<UserProfileAttribute[]> =
  withMakeRequest(
    async () =>
      await Request.getInstance().get(
        `/user-profile-attributes`,
        getRequestConfig({ addEnvDomain: true })
      ),
    (data: UserProfileAttributesListResponse) => data.userAttributes
  );

const createUserProfileAttribute: (
  attributeObj: UserProfileAttributeCreateRequest | UserProfileAttribute
) => Promise<UserProfileAttribute> = withMakeRequest(
  async (attributeObj) =>
    await Request.getInstance().post(
      `/user-profile-attributes`,
      attributeObj,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: UserProfileAttributeCreateResponse) => data.userAttribute
);

const updateUserProfileAttributeByKey: (
  attributeObj: UserProfileAttributeUpdateRequest | UserProfileAttribute
) => Promise<UserProfileAttribute> = withMakeRequest(
  async (attributeObj) =>
    await Request.getInstance().patch(
      `/user-profile-attributes/${attributeObj.key}`,
      attributeObj,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: UserProfileAttributeUpdateResponse) => data.userAttribute
);

const deleteUserProfileAttributeByKey: (
  attributeKey: string
) => Promise<boolean> = withMakeRequest(
  async (attributeKey) =>
    await Request.getInstance().delete(
      `/user-profile-attributes/${attributeKey}`,
      getRequestConfig({ addEnvDomain: true })
    )
);

const getWebhookConsumerPortal: () => Promise<GenerateWebhookPortalLinkResponse> =
  withMakeRequest(
    async () =>
      await Request.getInstance().get(
        '/webhooks/portal-url',
        getRequestConfig({ addEnvDomain: true })
      )
  );

const getDirectoriesForOrganization: (
  organizationId: string
) => Promise<DirectoryData[]> = withMakeRequest(
  async (organizationId: string) =>
    await Request.getInstance().get(
      `/organizations/${organizationId}/directories?page_size=100`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: ListDirectoryResponse) => data.directories
);

const createDirectoryForOrganization: (
  organizationId: string,
  data: CreateDirectoryRequest
) => Promise<DirectoryData> = withMakeRequest(
  async (organizationId: string, data: CreateDirectoryRequest) =>
    await Request.getInstance().post(
      `/organizations/${organizationId}/directories`,
      data,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: CreateDirectoryResponse) => data.directory
);

const getDirectoryDetailsById: (
  organizationId: string,
  directoryId: string
) => Promise<DirectoryData> = withMakeRequest(
  async (organizationId: string, directoryId: string) =>
    await Request.getInstance().get(
      `/organizations/${organizationId}/directories/${directoryId}`,
      getRequestConfig({ addEnvDomain: true })
    ),
  (data: GetDirectoryByIdResponse) => data.directory
);

const updateDirectoryById: (
  organizationId: string,
  directory: DirectoryData
) => Promise<DirectoryData> = withMakeRequest(
  async (organizationId: string, directory: DirectoryData) =>
    await Request.getInstance().patch(
      `/organizations/${organizationId}/directories/${directory.id}`,
      directory,
      getRequestConfig({ addEnvDomain: true })
    )
);

const toggleDirectoryStatus: (
  organizationId: string,
  directoryId: string,
  shouldEnable: boolean
) => Promise<DirectoryData> = withMakeRequest(
  async (organizationId: string, directoryId: string, shouldEnable: boolean) =>
    await Request.getInstance().patch(
      `/organizations/${organizationId}/directories/${directoryId}:${shouldEnable ? 'enable' : 'disable'}`,
      {},
      getRequestConfig({ addEnvDomain: true })
    )
);

const getDirectoryEvents: (
  organizationId: string,
  pageToken: string,
  pageSize: number
) => Promise<EventsListResponse> = withMakeRequest(
  async (organizationId: string, pageToken: string, pageSize: number) =>
    await Request.getInstance().post(
      `/events?page_token=${pageToken}&page_size=${pageSize}`,
      {
        organization_id: organizationId,
        event_types: Object.values(DirectoryEventTypes)
      },
      getRequestConfig({ addEnvDomain: true, camelCaseResponse: false })
    ),
  (data: EventsListResponse) => {
    return {
      ...camelcaseKeys(data as Record<any, any>, { deep: true }),
      events: data.events.map((event) => ({
        ...camelcaseKeys(event as Record<any, any>, {
          deep: true
        }),
        rawPayload: JSON.stringify(event, undefined, 2)
      })) as EventData[]
    };
  }
);

const getDirectoryUsersList: (
  request: ListDirectoryUserRequest
) => Promise<ListDirectoryUserResponse> = withMakeRequest(
  async (request: ListDirectoryUserRequest) =>
    Request.getInstance().get(
      `/organizations/${request.organizationId}/directories/${request.directoryId}/users?include_detail=${request.groupId ? 'false' : 'true'}&page_token=${request.pageToken}&page_size=${request.pageSize}${request.groupId ? `&directory_group_id=${request.groupId}` : ''}`,
      getRequestConfig({ addEnvDomain: true, camelCaseResponse: false })
    ),
  (data: ListDirectoryUserResponse) => {
    return {
      ...camelcaseKeys(data as Record<any, any>, { deep: true }),
      users: data.users.map((user: Record<any, any>) => ({
        ...camelcaseKeys(user as Record<any, any>, {
          deep: true
        }),
        userDetail: user.user_detail,
        userDetailRaw: JSON.stringify(user.user_detail, undefined, 2)
      })) as DirectoryUser[]
    };
  }
);

const getDirectoryGroupList: (
  request: ListDirectoryGroupRequest
) => Promise<ListDirectoryGroupResponse> = withMakeRequest(
  async (request: ListDirectoryGroupRequest) =>
    Request.getInstance().get(
      `/organizations/${request.organizationId}/directories/${request.directoryId}/groups`,
      getRequestConfig({ addEnvDomain: true })
    )
);

const generateDirectoryBearerToken: (
  request: GenerateDirectorySecretRequest
) => Promise<GenerateDirectorySecretResponse> = withMakeRequest(
  async ({
    organizationId,
    directoryId,
    shouldRegenerate = false
  }: GenerateDirectorySecretRequest) =>
    Request.getInstance().post(
      `/organizations/${organizationId}/directories/${directoryId}/secrets${shouldRegenerate ? ':regenerate' : ''}`,
      {},
      getRequestConfig({ addEnvDomain: true })
    )
);

export {
  getFeatureFlags,
  login,
  signup,
  logoutMember,
  getOrganizations,
  searchOrganizations,
  getOrganizationById,
  addOrganization,
  updateOrganization,
  updateOrganizationSettings,
  deleteOrganizationById,
  getCurrentUserDetails,
  getCurrentWorkspaceDetails,
  getCurrentEnvironmentList,
  createEnvironment,
  updateEnvironmentById,
  createEnvironmentAssetUploadLink,
  uploadEnvironmentAsset,
  updateLoggedInMember,
  updateWorkspace,
  getWorkspaceMembers,
  addMember,
  deleteMember,
  getClientCredentials,
  updateClientCredentials,
  generateNewClientSecret,
  deleteClientSecret,
  getConnectionList,
  addSSOConnectionForOrganization,
  addConnectionForEnvironment,
  getSSOConnectionDetails,
  getEnvironmentConnectionDetails,
  updateConnection,
  updateConnectionForEnvironment,
  toggleConnection,
  toggleEnvironmentConnection,
  deleteConnection,
  deleteEnvironmentConnection,
  getTestConnectionResult,
  getOrganizationDomains,
  addOrganizationDomain,
  deleteOrganizationDomain,
  getPortalLinksForOrg,
  generatePortalLink,
  deletePortalLink,
  createPortalSession,
  getCurrentPortalCustomizationConfig,
  getPortalCustomizationSettingsForEnvironment,
  updatePortalCustomizationSettingsForEnvironment,
  getUserProfileAttributes,
  createUserProfileAttribute,
  updateUserProfileAttributeByKey,
  deleteUserProfileAttributeByKey,
  getDirectoriesForOrganization,
  createDirectoryForOrganization,
  getDirectoryDetailsById,
  updateDirectoryById,
  toggleDirectoryStatus,
  getDirectoryEvents,
  getWebhookConsumerPortal,
  getDirectoryUsersList,
  getDirectoryGroupList,
  generateDirectoryBearerToken
};
