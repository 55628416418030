export enum SINGLE_SIGNON_TAB_TYPES {
  QUICKSTART = 'getting-started',
  ATTRIBUTES = 'attributes'
}

const TRANSLATION_BASE_PATH = `pages.authentication.single_sign_on`;

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

export { getTranslationKey };
