import { Component, JSXElement, lazy } from 'solid-js';
import { Route } from '@solidjs/router';
import {
  CUSTOMER_PORTAL_BASE_PATHS,
  CustomerPortalPath,
  getSlug,
  OrgDetailsDataType
} from '~/components/AppRouter/utils.ts';
import AdminPortalBase from '~/pages/AdminPortal/AdminPortalBase.tsx';

import {
  getWorkspacePageComponent,
  LayoutTypes
} from '~/components/Layout/utils.tsx';
import withOrganizationContext from '~/pages/AdminPortal/AdminPortalWithOrganizationContext.tsx';
import withAdminPortalRenderer from '~/pages/AdminPortal/AdminPortalRenderer.tsx';

const SSOConnectionsList = lazy(
  () => import('~/pages/Authentication/Connections/SSOConnectionsList.tsx')
);
const SSOConnectionDetails = lazy(
  () => import('~/pages/Authentication/Connections/SSOConnectionDetails.tsx')
);
const SSOConnectionEmbeddedDoc = lazy(
  () =>
    import('~/pages/Authentication/Connections/SSOConnectionEmbeddedDoc.tsx')
);

const DirectoryList = lazy(
  () => import('~/pages/DirectorySync/DirectoryList.tsx')
);

const DirectoryDetails = lazy(
  () => import('~/pages/DirectorySync/DirectoryDetails.tsx')
);

export default function AdminPortalAppRoutes(): JSXElement {
  return (
    <Route
      path={getSlug.customerPortalBaseNamespace()}
      component={AdminPortalBase as Component<any>}
    >
      <Route
        path={getSlug.portalLink(
          undefined,
          CustomerPortalPath.LIST_CONNECTIONS,
          CUSTOMER_PORTAL_BASE_PATHS.UNKNOWN
        )}
        component={withAdminPortalRenderer(SSOConnectionsList)}
      />
      <Route
        path={getSlug.portalLink(
          undefined,
          CustomerPortalPath.CONNECTION_DETAILS,
          CUSTOMER_PORTAL_BASE_PATHS.UNKNOWN
        )}
        component={withAdminPortalRenderer(SSOConnectionDetails)}
      />
      <Route
        path={getSlug.portalLink(
          undefined,
          CustomerPortalPath.LIST_DIRECTORIES,
          CUSTOMER_PORTAL_BASE_PATHS.UNKNOWN
        )}
        component={withAdminPortalRenderer(DirectoryList)}
      />
      <Route
        path={getSlug.portalLink(
          undefined,
          CustomerPortalPath.DIRECTORY_DETAILS,
          CUSTOMER_PORTAL_BASE_PATHS.UNKNOWN
        )}
        component={withAdminPortalRenderer(DirectoryDetails)}
      />
      <Route
        path={getSlug.portalLink(
          undefined,
          CustomerPortalPath.CONNECTION_DOC,
          CUSTOMER_PORTAL_BASE_PATHS.UNKNOWN
        )}
        component={withOrganizationContext(
          getWorkspacePageComponent(
            SSOConnectionEmbeddedDoc,
            LayoutTypes.ADMIN_PORTAL_DOC
          ),
          undefined,
          OrgDetailsDataType.SSO_CONNECTIONS
        )}
      />
    </Route>
  );
}
