import { Component, JSXElement, mergeProps, Show, splitProps } from 'solid-js';
import { tv, VariantProps } from 'tailwind-variants';
import { twMerge } from 'tailwind-merge';
import './alert.scss';
import { AlertTriangle, InfoIcon } from 'lucide-solid';

const styles = tv({
  base: 'alert',
  defaultVariants: { variant: 'info' },
  variants: {
    variant: {
      error: 'alert-variant_error',
      success: 'alert-variant_success',
      info: 'alert-variant_info',
      warning: 'alert-variant_warning'
    }
  }
});

type AlertVariantProps = VariantProps<typeof styles>;

type AlertProps = {
  children: JSXElement;
  class?: string;
  skipLogo?: boolean;
} & AlertVariantProps;

export default function Alert(props: AlertProps): JSXElement {
  const merged = mergeProps(
    {
      class: '',
      skipLogo: false
    },
    props
  );
  const [componentProps, variantProps] = splitProps(merged, [
    'children',
    'class'
  ]);
  const classVariants = styles(variantProps);
  const Icon: Component = (() => {
    switch (variantProps.variant) {
      case 'error':
        return () => <AlertTriangle width={16} />;
      case 'info':
        return () => (
          <InfoIcon
            class={`text-[#0588F0]`}
            width={16}
          />
        );
      default:
        return () => <></>;
    }
  })();

  const defaultClasses = 'mb-2';

  return (
    <div
      {...componentProps}
      class={twMerge(
        classVariants,
        defaultClasses,
        `flex items-start`,
        merged.class
      )}
    >
      <Show when={!merged.skipLogo}>
        <div>
          <Icon />
        </div>
      </Show>
      <span class={`ml-1 mt-1`}>{componentProps.children}</span>
    </div>
  );
}
