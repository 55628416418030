import { ApiErrorInstance, withHandleError } from '~/api';
import { HttpStatusCode } from 'axios';
import { getLocalizedString } from '~/i18n/utils.ts';
import { SocialProviders } from '~/pages/Authentication/Connections/SocialConnections/utils.ts';

const MIN_SEARCH_CHARS = 3;

const TRANSLATION_BASE_PATH = `pages.login`;

const initiateSocialLogin = (provider: SocialProviders) => {
  window.location.assign(`/app/login/authorize?provider=${provider}`);
};

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

const handleLoginErrors = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  const translationBasePath = 'server_errors.login';
  if (serverError.httpStatus === HttpStatusCode.NotFound) {
    return getLocalizedString(`${translationBasePath}.not_found`, {
      intl
    });
  }
});

export {
  MIN_SEARCH_CHARS,
  getTranslationKey,
  handleLoginErrors,
  initiateSocialLogin
};
